import React from "react";
import { Space } from 'antd';

import Navbar from "./Navbar.jsx";
import Footer from "./Footer.jsx";
import davidLogo from "../images/david-logo.jpg";

export default function AboutScreen () {
  return (
    <div>
      <Navbar />
      <div className="about-screen">
        <section className="hero is-info">
          <div className="hero-body">
            <div className="container box full-height">
              <div className="columns is-multiline is-centered">
                <div className="column is-narrow">
                  <div className="content">
                    <h2>About us</h2>
                  </div>
                </div>
              </div>

              <div className="columns is-multiline align-bottom is-centered">
                <div className="column is-narrow">
                  <div className="content has-text-centered">
                    <div className="david-logo-container">
                      <img src={davidLogo} alt="david-logo"/>
                    </div>
                    <h3 className="title has-text-black">IArxiv<sup className="beta-label"> beta</sup></h3>
                    <hr/>

                    <Space direction="vertical" size={40}>
                      <div>
                        <h4>Original idea and coordinators</h4>
                        <ul className="is-unstyled">
                          <li>Ezequiel Alvarez (ICAS)</li>
                          <li>Cesar Miquel (Easytech)</li>
                        </ul>
                      </div>


                      <div>
                        <h4>Developed by:</h4>
                        <h5>1st stage</h5>
                        <ul className="is-unstyled">
                          <li>Federico Lamagna (CAB CNEA)</li>
                          <li>Manuel Szewc (ICAS)</li>
                          <li>Ariel Cerqueiras (Easytech)</li>
                        </ul>

                        <h5>2nd stage</h5>
                        <ul className="is-unstyled">
                          <li>Marcelo Calarota (FI-UBA)</li>
                          <li>Sebastian Campoamor (FI-UBA)</li>
                          <li>Guillermo Recalde (FI-UBA)</li>
                        </ul>
                      </div>

                      <div>
                        <h4>Powered by:</h4>
                        <ul className="is-unstyled">
                          <li><a href="https://icas.unsam.edu.ar/" rel="noopener noreferrer" target="_blank">icas.unsam.edu.ar</a></li>
                          <li><a href="https://easytechgreen.com/" rel="noopener noreferrer" target="_blank">easytechgreen.com</a></li>
                          <li><a href="https://physics.illinois.edu/" rel="noopener noreferrer" target="_blank">physics.illinois.edu</a></li>
                          <li><a href="https://vercel.com?utm_source=iarxiv&utm_campaign=oss" rel="noopener noreferrer" target="_blank">Vercel</a></li>
                          <li><a href="https://unsam.edu.ar/" rel="noopener noreferrer" target="_blank">ECyT.unsam.edu.ar</a></li>
                        </ul>
                        <em>Part of IArxiv development corresponds to a Master Thesis for <a href="https://www.fi.uba.ar" rel="noopener noreferrer" target="_blank">FI-UBA</a><br /> supervised by Prof. Dr. Mariano Beiró (UBA-CONICET)</em>
                      </div>

                      <div>
                        <h4>Get involved!</h4>
                        <p className="is-500px">
                          IArxiv is maintained and developed with great effort and care.
                          There is much currently being done and much more to be done: many specific implementations, but also many Machine Learning challenges.
                          If you find it useful and want to contribute, we have official and/or volunteer ways to do it; and we would be very grateful.
                          If your team wants to get involved in Research+Development tasks within IArxiv, we would be happy to explore institution partnerships.
                          Please contact <a className="has-text-primary-blue" href="mailto:sequi@unsam.edu.ar?subject=Get involved with IArxiv">Ezequiel Alvarez</a> for further information.
                        </p>
                      </div>
                    </Space>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
