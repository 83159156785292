import React, { useContext, useEffect } from "react";
import { Context } from "./Context.jsx";

export default function LogoutScreen ({ history }) {
  const context = useContext(Context);

  useEffect(() => {
    context.cleanSession();
    history.push("/login");
  }, [context, history]);

  return <div>Logging out!</div>;
};
