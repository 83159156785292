import { getURL, setParams } from "../utils/request-utils";

const abortSignal = { errorCode: 0, message: "Abort signal" };
const failFetch = { errorCode: -1, message: "Server is down" };
const badJSON = { errorCode: -2, message: "Bad response" };

async function apiRequest(route, { method = 'GET', json = true, token, body,  ...options }) {
  // Build request options.
  const requestOptions = {
    ...options,
    headers: {
      ...json && { 'Content-Type': 'application/json' },
      ...token && { Authorization: `Bearer ${token}` },
      ...options.headers,
    },
    method,
    ...body && { body: json ? JSON.stringify(body) : body },
  };

  const res = await fetch(route, requestOptions);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    // Look for extra error info in response.
    const errorInfo = await res.json();
    const errorMessage = errorInfo?.error || errorInfo?.problems?.toString();
    const error = new Error(errorMessage);
    error.info = errorInfo;
    error.status = res.status;
    throw error;
  }

  const data = await res.text();
  return data.length ? JSON.parse(data) : {};
}

/**
 * @param ac AbortController object
 * @param email Body data - string
 * @param password Body data - string
 * @param firstname Body data - string
 * @param lastname Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchRegister(ac, email, password, firstname, lastname) {
  const url = getURL("register");

  const options = {
    signal: ac.signal,
    method: "POST",
    body: JSON.stringify({
      email: email,
      password: password,
      firstname: firstname,
      lastname: lastname
    }),
    headers: {
      "Content-Type": "application/json"
    }
  };

  return sendRequest(url, options);
}


/**
 * @param registerToken URL param - string
 * @param email URL param - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchConfirmRegister(registerToken, email) {
  const url = setParams(getURL("confirmRegister"), [
    { id: "email", value: email },
    { id: "registerToken", value: registerToken },
  ]);

  const options = {
    method: "GET",
  };

  return sendRequest(url, options);
}

/**
 * @param ac AbortController object
 * @param email Body data - string
 * @param password Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchLogin(ac, email, password) {
  const url = getURL("login");

  const options = {
    signal: ac.signal,
    method: "POST",
    body: JSON.stringify({ email: email, password: password }),
    headers: {
      "Content-Type": "application/json"
    }
  };

  return sendRequest(url, options);
}

/**
 * @param ac AbortController object
 * @param assertion Body data - string; Encoded data sent by Arxiv.
 * @param digest Body data - string; Encoded data validation hash.
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchLoginWithArxiv(assertion, digest) {
  const url = getURL("loginWithArxiv");
  const body = { assertion, digest };
  return apiRequest(url, { method: "POST", body });
}

/**
 * @param ac AbortController object
 * @param assertion Body data - string; Encoded data sent by Arxiv.
 * @param digest Body data - string; Encoded data validation hash.
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
 function fetchGetLoginWithArxivLink(arxivUser) {
  const toArray = (values = "") => values
    .split(";")
    .map((value) => value.trim());

  const url = getURL("loginWithArxivLink");
  const body = {
    ...arxivUser,
    primary_categories: toArray(arxivUser.primary_categories),
    arxivids: toArray(arxivUser.arxivids),
    personid: "1234",
    __timeout__: 10,
    __userip__: "192.168.1.1",

  }
  return apiRequest(url, { method: "POST", body });
}

/**
 * @param ac AbortController object
 * @param email Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchForgotPassword(ac, email) {
  const url = getURL("forgotPassword");

  const options = {
    signal: ac.signal,
    method: "POST",
    body: JSON.stringify({
      email: email
    }),
    headers: {
      "Content-Type": "application/json"
    }
  };

  return sendRequest(url, options);
}

/**
 * @param ac AbortController object
 * @param token Body data - string
 * @param email Body data - string
 * @param password Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchResetPassword(ac, token, email, password) {
  const url = getURL("resetPassword");

  const options = {
    signal: ac.signal,
    method: "POST",
    body: JSON.stringify({
      email: email,
      token: token,
      password: password
    }),
    headers: {
      "Content-Type": "application/json"
    }
  };

  return sendRequest(url, options);
}

/**
 * @param token Body data - string
 * @param password Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchUpdatePassword(token, password) {
  const url = getURL("updatePassword");
  const body = { password };
  return apiRequest(url, { method: "PATCH", token, body });
}

/**
 * @param accessToken Header data - Authenticated session token
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchGetSettings(accessToken) {
  const url = getURL("getSettings");

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    }
  };

  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
 function fetchGetCategories(accessToken) {
  const url = getURL("categories");
  return apiRequest(url, { token: accessToken });
}

/**
 * Update user settings on third party integration workflow
 *
 * @param string token
 * @param Object settings
 * @returns Promise
 */
function fetchUpdateSettingsIntegration(token, settings) {
  const url = getURL("updateSettingsIntegration");
  const body = { ...settings };
  return apiRequest(url, { method: "PATCH", token, body });
}

/**
 * @param ac AbortController object
 * @param accessToken Header data - Authenticated session token
 * @param followedCategoriesID Body data - array of positive numbers
 * @param checkedPapers Body data - object { ownPapers: array of {paperID: paperID, age: yyyy format number}, notOwnPapers: array of paperID}
 * @param abstractThresholdScore Body data - float
 * @param dailyEmail Body data - boolean
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchUpdateSettings(ac, accessToken, followedCategoriesID, checkedPapers, abstractThresholdScore, dailyEmail, weeklyEmail, digestThresholdScore) {
  const url = getURL("updateSettings");

  const options = {
    signal: ac.signal,
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      followedCategoriesID: followedCategoriesID,
      checkedPapers: checkedPapers,
      abstractThresholdScore: abstractThresholdScore,
      dailyEmail: dailyEmail,
      weeklyEmail: weeklyEmail,
      digestThresholdScore: digestThresholdScore,
    })
  };

  return sendRequest(url, options);
}

/**
 * @param ac AbortController object
 * @param accessToken Header data - Authenticated session token
 * @param from Query param - string
 * @param to Query param - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchGetPapers(ac, accessToken, from, to) {
  const url = setParams(getURL("getPapers"), [
    { id: "from", value: from },
    { id: "to", value: to }
  ]);

  const options = {
    signal: ac.signal,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param paperID Body data - string
 * @param scoreType Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchUpdateAffinity(accessToken, paperID, scoreType) {
  const url = getURL("updateAffinity");

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      items: {
        [paperID]: scoreType
      }
    })
  };

  return sendRequest(url, options);
}


/**
 * @param token Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchUpdateAffinityToken(token) {
  const url = getURL("updateAffinityToken");

  const options = {
    method: "POST",
    body: JSON.stringify({ token: token }),
    headers: {
      "Content-Type": "application/json"
    }
  };

  return sendRequest(url, options);
}

/**
 * @param token Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchUnsubscribeDailyEmail(token) {
  const url = getURL("unsubscribe");

  const options = {
    method: "PUT",
    body: JSON.stringify({ token: token }),
    headers: {
      "Content-Type": "application/json"
    }
  };

  return sendRequest(url, options);
}

/**
 * @param token Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function fetchUnsubscribeWeeklyEmail(token) {
  const url = getURL("unsubscribeWeekly");

  const options = {
    method: "PUT",
    body: JSON.stringify({ token: token }),
    headers: {
      "Content-Type": "application/json"
    }
  };

  return sendRequest(url, options);
}

const defaultCatchValidation = (json, history) => {
  // Check JSON error type
  if (!isNaN(json.errorCode) && json.message) {
    // Expected JSON data: Positive error-codes are generated in backend, any else come from fetch-service
    switch (json.errorCode) {
      case 5: // Session token not-found/expired
        console.error(json.message);
        history.push("/logout");
        break;
      case 0: // Abort signal triggered nothing to do
        console.error(json.message);
        break;
      case -1: // Fetch has been failed, maybe server is down
        console.error(json.message);
        history.push("/maintenance");
        break;
      case -2: // Cant parse response as JSON, for some reason backend failed.
        console.error(json.message);
        break;
      default:
        // Any other errorCode ignored
        console.error(json.message);
        break;
    }
  } else {
    // Unexpected json data from backend: Validation error / Internal error.
    console.error("Unexpected error: ", json);
  }
}

const sendRequest = (url, options) =>
  new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => {
        if (response.ok) {
          // Expected fetch response status (ok: 200)
          response
            .json()
            .then(json => {
              // Expected response body data: JSON
              resolve(json);
            })
            .catch(error => {
              // Unexpected body response data: non-JSON
              reject(badJSON);
            });
        } else {
          response
            .json()
            .then(json => {
              // Expected response body error data: JSON
              reject(json);
            })
            .catch(error => {
              // Unexpected response body error data: non-JSON
              reject(badJSON);
            });
        }
      })
      .catch(error => {
        if (error.name === "AbortError") {
          // Abort fetch signal triggered
          console.log("Abort signal !");
          reject(abortSignal);
        }
        // Unexpected fetch error
        console.log("Unexpected fetch ERROR catch: ", error);
        reject(failFetch);
      });
  });

export {
  defaultCatchValidation,
  fetchRegister, fetchConfirmRegister,
  fetchLogin, fetchLoginWithArxiv, fetchGetLoginWithArxivLink,
  fetchForgotPassword, fetchResetPassword, fetchUpdatePassword,
  fetchGetSettings, fetchUpdateSettings, fetchUpdateSettingsIntegration, fetchGetCategories,
  fetchGetPapers, fetchUpdateAffinity,
  fetchUpdateAffinityToken, fetchUnsubscribeDailyEmail, fetchUnsubscribeWeeklyEmail
};
