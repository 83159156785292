import React, { useState, useContext, useEffect } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MathJax from "react-mathjax2";
import Accordion from "react-collapsy";
import "react-collapsy/lib/index.css";
import { Context } from "./Context.jsx";
import { defaultCatchValidation, fetchGetPapers, fetchUpdateAffinity } from "../services/fetch-service";

import Navbar from "./Navbar.jsx";
import Footer from "./Footer.jsx";
import moment from "moment";

let searchAbortController = new AbortController();

export default function HomeScreen ({ history }) {
  const context = useContext(Context);
  if (context.session.accessToken === undefined) {
    history.push("/login");
  }

  if (
    !Array.isArray(context.session.categories) ||
    context.session.categories.length === 0
  ) {
    history.push("/settings");
  }

  const [fromDate, setFromDate] = useState(
    new Date(moment.utc().format("YYYY/MM/DD"))
  );
  const [toDate, setToDate] = useState(
    new Date(moment.utc().format("YYYY/MM/DD"))
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [papers, setPapers] = useState([]);
  const [abstractThresholdScore, setAbstractThresholdScore] = useState(0);

  const onSearch = (lastDay = false) => {
    if (loading) {
      return;
    }
    if (fromDate && toDate) {
      setLoading(true);
      setErrorMessage("");
      setPapers([]);
      searchAbortController.abort();
      searchAbortController = new AbortController();

      fetchGetPapers(searchAbortController, context.session.accessToken, lastDay ? "" : moment(fromDate).format("YYYY/MM/DD"), lastDay ? "" : moment(toDate).format("YYYY/MM/DD"))
      .then(json => {
          if (json.lastDay) {
            setFromDate(new Date(moment.utc(json.lastDay).format("YYYY/MM/DD")));
            setToDate(new Date(moment.utc(json.lastDay).format("YYYY/MM/DD")));
          }
          
          if (json.abstractThresholdScore) {
            setAbstractThresholdScore(json.abstractThresholdScore);
          }

          if (json.papers) {
            setPapers(json.papers);
          }
      })
      .catch(json => {
        defaultCatchValidation(json, history);

        if (json.errorCode && json.message) {
          setErrorMessage(json.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (
      context.session.accessToken === undefined ||
      !Array.isArray(context.session.categories) ||
      context.session.categories.length === 0
    ) {
      return;
    }
    onSearch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openPDF = paperID => {
    // Register event: Open PDF adds score SCORE_OPEN_PDF to paperID-userID affinity
    updateAffinity(paperID, "SCORE_OPEN_PDF");
  };

  const openAbs = paperID => {
    // Register event: Open PDF adds score SCORE_OPEN_PDF to paperID-userID affinity
    updateAffinity(paperID, "SCORE_OPEN_ABS");
  };

  const expandAbstract = paperID => {
    // Register event: Expand Abstract adds score SCORE_EXPAND_ABSTRACT to paperID-userID affinity
    updateAffinity(paperID, "SCORE_EXPAND_ABSTRACT");
  };

  const updateAffinity = (paperID, scoreType) => {
    if (paperID && scoreType) {
      fetchUpdateAffinity(context.session.accessToken, paperID, scoreType);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="home-screen">
        <section className="hero is-info">
          <div className="hero-body">
            <div className="container box full-height">
              <div className="columns is-multiline is-centered">
                <div className="column is-narrow">
                  <div className="content">
                    <h5>Search papers</h5>
                  </div>
                </div>
              </div>
              <div className="columns is-multiline align-bottom">
                <div className="column is-narrow">
                  <div className="content">
                    <span>From date</span>
                    <div>
                      <DatePicker
                        className="input"
                        dateFormat="yyyy/MM/dd"
                        selected={fromDate}
                        onChange={date => setFromDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-narrow">
                  <div className="content">
                    <span>To date</span>
                    <div>
                      <DatePicker
                        className="input"
                        dateFormat="yyyy/MM/dd"
                        selected={toDate}
                        onChange={date => setToDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-narrow">
                  <div className="content">
                    <button
                      className={
                        "button is-block is-info is-fullwidth " +
                        (loading ? "is-loading" : "")
                      }
                      onClick={onSearch.bind(this, false)}
                    >
                      Search
                    </button>
                  </div>
                </div>
                {errorMessage && (
                  <div className="column is-narrow">
                    <div className="content">
                      <span className="span-error has-text-danger">
                        {errorMessage}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {!loading && (
                <section className="container">
                  {!errorMessage &&
                    (!Array.isArray(papers) || papers.length === 0) && (
                      <div className="content paper-content">
                        <p className="has-text-weight-bold">
                          Papers not found in this date range for your followed categories.
                        </p>
                      </div>
                    )}
                  {!errorMessage && Array.isArray(papers) && papers.length > 0 && (
                    <MathJax.Context
                      input="tex"
                      onLoad={() => console.log("Loaded MathJax script!")}
                      onError={(MathJax, error) => {
                        console.warn(error);
                        console.log(
                          "Encountered a MathJax error, re-attempting a typeset!"
                        );
                        MathJax.Hub.Queue(MathJax.Hub.Typeset());
                      }}
                      script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-AMS-MML_HTMLorMML"
                      options={{
                        asciimath2jax: {
                          useMathMLspacing: true,
                          delimiters: [["\\(", "\\)"]],
                          preview: "none"
                        }
                      }}
                    >
                      <div>
                        {papers.map((paper, index) => (
                          <div
                            key={paper.id}
                            className="content paper-content"
                            paperid={paper.id}
                            affinity={paper.userAffinity}
                          >
                            <p className="paper-date ">
                              <i>
                                {`${moment(paper.publishDate).format(
                                  "YYYY/MM/DD"
                                )} `}
                              </i>
                            </p>
                            <p>{`[${index+1}] `}
                              <a
                                href={paper.linkAbs}
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={openAbs.bind(this, paper.id)}
                              >{paper.arxivID} </a>
                              {paper.mainCategory ? `(${paper.mainCategory}) ` : ""}
                              {`[score: ${paper.userAffinity.toFixed(2)}]`}</p>
                            <a
                              href={paper.linkPDF}
                              rel="noopener noreferrer"
                              target="_blank"
                              onClick={openPDF.bind(this, paper.id)}
                            >
                              <h3 className="paper-title">
                                <MathJax.Text text={paper.title} />
                              </h3>
                            </a>

                            <span>{paper.authorNames ? `${paper.authorNames.join(" - ")}` : "Unknown authors"}</span>
                            <br/>
                            {paper.comments ? (<p>Comments: <i>{paper.comments}</i></p>) : <br/>}

                            {paper.userAffinity >= abstractThresholdScore && (
                              <div className="paper-abstract">
                                <MathJax.Text text={paper.abstract} />
                              </div>
                            )}
                            {paper.userAffinity < abstractThresholdScore && (
                              <Accordion
                                className="accordion"
                                headerClass="accordion-header"
                                title="Abstract"
                                onToggle={isOpen => {
                                  if (isOpen) {
                                    expandAbstract(paper.id);
                                  }
                                }}
                              >
                                <div className="paper-abstract">
                                  <MathJax.Text text={paper.abstract} />
                                </div>
                              </Accordion>
                            )}
                          </div>
                        ))}
                      </div>
                    </MathJax.Context>
                  )}
                </section>
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
