import React, { useState, useEffect } from "react";

import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { defaultCatchValidation, fetchConfirmRegister } from "../services/fetch-service";

export default function ConfirmRegisterScreen ({history}) {
  let { email, registerToken } = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchConfirmRegister(registerToken, email)
    .then(json => {
    })
    .catch(json => {
      defaultCatchValidation(json, history);

      if (json.errorCode && json.message) {
        setErrorMessage(json.message);
      }
    })
    .finally(() => {
      setLoading(false);
    });
  }, [email, registerToken, history]);

  return (
    <section className="hero has-background-white-bis is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          {loading ? (
          <div className="column is-4 is-offset-4">
            <h3 className="title has-text-black">Checking account</h3>
            <p className="subtitle has-text-black">Please wait...</p>
          </div>
          ) : errorMessage ? (
          <div className="column is-4 is-offset-4">
            <h3 className="title has-text-black">Sorry!</h3>
            <p className="subtitle has-text-black">{errorMessage}</p>
          </div>
          ) : (
          <div className="column is-4 is-offset-4">
            <h3 className="title has-text-black">Email confirmed!</h3>
            <p className="subtitle has-text-black">Proceed to <Link to="/logout">Login</Link>.</p>
          </div>          
          )}
        </div>
      </div>
    </section>
  );
};
