export const apiConfig = {
  baseUrl: process.env.REACT_APP_API_BACKEND_URL || "http://localhost:3002/api/",
  endpoints: {
    login: "v1/login",
    loginWithArxiv: "v1/register/arxiv",
    loginWithArxivLink: "dev/register/arxiv/new-user",
    register: "v1/register",
    confirmRegister:"v1/confirm-register/:email/:registerToken",
    forgotPassword:"v1/forgot-password",
    resetPassword:"v1/reset-password",
    updatePassword:"v1/user/password",
    categories: "v1/categories",
    getSettings: "v1/user/settings",
    updateSettings: "v1/user/update-settings",
    updateSettingsIntegration: "v1/user/settings/integration",
    getPapers: "v1/user/papers?from=:from&to=:to",
    updateAffinity: "v1/user/update-affinity",
    updateAffinityToken: "v1/user/update-affinity-token",
    unsubscribe: "v1/user/unsubscribe",
    unsubscribeWeekly: "v1/user/unsubscribe-weekly",
  },
};
