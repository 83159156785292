import React from "react";

export default function MaintenanceScreen () {
  return (
    <div className="register-screen">
      <section className="hero has-background-white-bis is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-4 is-offset-4">
              <h3 className="title has-text-black">Server in maintenance</h3>
              <p className="subtitle has-text-black">IArxiv is currently down for maintenance.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
