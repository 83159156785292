/* eslint-disable no-useless-escape */
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { Context } from "./Context.jsx";
import { defaultCatchValidation, fetchLogin } from "../services/fetch-service";
import { Link } from "react-router-dom";
import icasLogo from "../images/icas-logo.png";
import easytechLogo from "../images/easytechgreen-logo.png";
import davidLogo from "../images/david-logo.jpg";
import illinoisLogo from "../images/illinois-logo.png";

let loginAbortController = new AbortController();

export default function LoginScreen ({ history }) {
  const context = useContext(Context);

  const { register, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = data => {
    if (loading) {
      return;
    }
    if (data.email && data.password) {
      setLoading(true);
      setErrorMessage("");
      loginAbortController.abort();
      loginAbortController = new AbortController();
      fetchLogin(loginAbortController, data.email, data.password)
        .then(json => {
          if (
            json.email &&
            json.firstname &&
            json.lastname &&
            json.accessToken &&
            json.expireTime &&
            json.categories
          ) {
            // Set user data in context.
            context.setSession({
              firstname: json.firstname,
              lastname: json.lastname,
              email: json.email,
              accessToken: json.accessToken,
              expireTime: json.expireTime,
              categories: json.categories
            });
            history.push("/home");
          }
        })
        .catch(json => {
          defaultCatchValidation(json, history);
  
          if (json.errorCode && json.message) {
            setErrorMessage(json.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="login-screen">
      <section className="hero has-background-white-bis is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-4 is-offset-4">
              <div className="david-logo-container">
                <img src={davidLogo} alt="david-logo"/>
              </div>
              <p className="has-text-grey">
                <Link to="/about">About us</Link>
                <label>&nbsp;·&nbsp;</label>
                <Link to="/faq">F.A.Q.</Link>
              </p>
              <h3 className="title has-text-black">IArxiv<sup className="beta-label"> beta</sup></h3>
              <p className="subtitle has-text-black">
                Please login to proceed.
              </p>
              <div className="box">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="email"
                        placeholder="Email"
                        autoFocus=""
                        name="email"
                        ref={register({
                          required: "Required",
                          maxLength: { value: 50, message: "Too long" },
                          pattern: {
                            value: RegExp(
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/
                            ),
                            message: "Invalid"
                          }
                        })}
                      />
                      {errors.email && (
                        <span className="has-text-danger">
                          * {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="password"
                        placeholder="Password"
                        name="password"
                        ref={register({ required: "Required" })}
                      />
                      {errors.password && (
                        <span className="has-text-danger">
                          * {errors.password.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <button
                    className={
                      "button is-block is-info is-large is-fullwidth " +
                      (loading ? "is-loading" : "")
                    }
                    type="submit"
                  >
                    Login
                  </button>
                  {errorMessage && (
                    <div className="field">
                      <div className="control">
                        <span className="has-text-danger">{errorMessage}</span>
                      </div>
                    </div>
                  )}
                </form>
              </div>
              <p className="has-text-grey">
                <Link to="/register">Register</Link>
                <label>&nbsp;·&nbsp;</label>
                <Link to="/forgot-password">Forgot Password</Link>
              </p>
              <a
                href="http://icas.unsam.edu.ar/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="logo-container">
                  <img src={icasLogo} alt="icas-logo" />
                </div>
              </a>
              <a
                href="https://easytechgreen.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="logo-container">
                  <img src={easytechLogo} alt="easytech-logo" />
                </div>
              </a>
              <a
                href="https://physics.illinois.edu/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="logo-container">
                  <img src={illinoisLogo} alt="illinois-logo" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
