import React from "react";

import {
  getSessionCookie,
  setSessionCookie,
  cleanSessionCookie
} from "../utils/cookies-utils";

export const Context = React.createContext();

class ContextProvider extends React.Component {
  state = {
    session: getSessionCookie(),
    setSession: session => this.setSession(session),
    cleanSession: () => this.cleanSession()
  };

  cleanSession = () => {
    cleanSessionCookie();
    this.setState({
      session: getSessionCookie()
    });
  };

  setSession = session => {
    setSessionCookie(session);
    this.setState({
      session: getSessionCookie()
    });
  };

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default ContextProvider;
