import React from "react";
import { Router, Switch, Route } from "react-router";
import { createBrowserHistory } from "history";
import 'antd/dist/antd.css';

import ContextProvider from "./Context.jsx";
import LoginScreen from "./LoginScreen.jsx";
import LoginWithArxivScreen from "./LoginWithArxivScreen.jsx";
import LogoutScreen from "./LogoutScreen.jsx";
import RegisterScreen from "./RegisterScreen.jsx";
import ConfirmRegisterScreen from "./ConfirmRegisterScreen.jsx";
import ForgotPasswordScreen from "./ForgotPasswordScreen.jsx";
import ResetPasswordScreen from "./ResetPasswordScreen.jsx";
import HomeScreen from "./HomeScreen.jsx";
import SettingsScreen from "./SettingsScreen.jsx";
import AboutScreen from "./AboutScreen.jsx";
import FAQScreen from "./FAQScreen.jsx";
import PaperScreen from "./PaperScreen.jsx";
import UnsubscribeScreen from "./UnsubscribeScreen.jsx";
import UnsubscribeWeeklyScreen from "./UnsubscribeWeeklyScreen.jsx";
import MaintenanceScreen from "./MaintenanceScreen.jsx";
import ForbiddenScreen from "./ForbiddenScreen.jsx";

export default function App () {
  const history = createBrowserHistory();
  return (
    <div className="App">
      <ContextProvider>
        <Router history={history}>
          <Switch>
            <Route path="/login/arxiv" component={LoginWithArxivScreen} />
            <Route path="/login" component={LoginScreen} />
            <Route path="/logout" component={LogoutScreen} />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/confirm-register/:email/:registerToken" component={ConfirmRegisterScreen} />
            <Route path="/forgot-password" component={ForgotPasswordScreen} />
            <Route path="/reset-password/:email/:token" component={ResetPasswordScreen} />
            <Route path="/home" component={HomeScreen} />
            <Route path="/settings" component={SettingsScreen} />
            <Route path="/about" component={AboutScreen} />
            <Route path="/faq" component={FAQScreen} />
            <Route path="/paper/:token/:hexURL" component={PaperScreen} />
            <Route path="/unsubscribe/:token" component={UnsubscribeScreen} />
            <Route path="/unsubscribe-weekly/:token" component={UnsubscribeWeeklyScreen} />
            <Route path="/maintenance" component={MaintenanceScreen} />
            <Route path="*" component={ForbiddenScreen} />
          </Switch>
        </Router>
      </ContextProvider>
    </div>
  );
};
