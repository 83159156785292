import * as Cookies from "js-cookie";

export const getSessionCookie = () => {
  const sessionCookie = Cookies.get("session");

  if (sessionCookie === undefined) {
    return {};
  } else {
    return JSON.parse(sessionCookie);
  }
};

export const setSessionCookie = (session) => {
  Cookies.remove("session");

  const data = {
    accessToken: session.accessToken,
    email: session.email,
    firstname: session.firstname,
    lastname: session.lastname,
    categories: session.categories,
  }

  Cookies.set("session", JSON.stringify(data) );// , { expires: new Date(session.expireTime) });

};

export const cleanSessionCookie = () => {
  Cookies.remove("session");
};

