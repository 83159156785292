/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { defaultCatchValidation, fetchResetPassword } from "../services/fetch-service";

let resetPasswordAbortController = new AbortController();

export default function ResetPasswordScreen ({history}) {
  let { email, token } = useParams();

  const { register, handleSubmit, errors, watch } = useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = data => {
    if (loading) {
      return;
    }
    if (data.password) {
      setLoading(true);
      setErrorMessage("");
      resetPasswordAbortController.abort();
      resetPasswordAbortController = new AbortController();
      
      fetchResetPassword(resetPasswordAbortController, token, email, data.password)
      .then(json => {
        if (json.success) {
          setSuccess(json.success);
        }
      })
      .catch(json => {
        defaultCatchValidation(json, history);

        if (json.errorCode && json.message) {
          setErrorMessage(json.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <section className="hero has-background-white-bis is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          {success ? (
          <div className="column is-4 is-offset-4">
            <h3 className="title has-text-black">Your password has been reset</h3>
            <p className="subtitle has-text-black">Go to <Link to="/login">Login</Link>.</p>
          </div>
          ) : (
          <div className="column is-4 is-offset-4">
            <h3 className="title has-text-black">Reset password</h3>
            <p className="subtitle has-text-black">Set new password for {email}</p>
            <div className="box">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <div className="control">
                    <input
                      className="input is-large"
                      type="password"
                      placeholder="Password"
                      name="password"
                      ref={register({ required: "Required" })}
                    />
                    {errors.password && (
                      <span className="has-text-danger">
                        * {errors.password.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <input
                      className="input is-large"
                      type="password"
                      placeholder="Confirm password"
                      name="confirmPassword"
                      ref={register({ required: "Required", validate: (value) => value === watch('password') || "Password must match" })}
                    />
                    {errors.confirmPassword && (
                      <span className="has-text-danger">
                        * {errors.confirmPassword.message}
                      </span>
                    )}
                  </div>
                </div>
                <button
                  className={
                    "button is-block is-info is-large is-fullwidth " +
                    (loading ? "is-loading" : "")
                  }
                  type="submit"
                >
                  Reset password
                </button>
                {errorMessage && (
                  <div className="field">
                    <div className="control">
                      <span className="has-text-danger">{errorMessage}</span>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
          )}
        </div>
      </div>
    </section>
  );
};
