import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { Col, Form, Layout, message, Row, Spin, Skeleton, Space, Switch } from "antd";
import { CheckOutlined, LockOutlined } from "@ant-design/icons";

import { useAccessToken } from "../hooks/useAccessToken";
import { useQueryString } from "../hooks/useQueryString";
import davidLogo from "../images/david-logo.jpg";
import { fetchGetCategories, fetchUpdatePassword, fetchUpdateSettingsIntegration, fetchLoginWithArxiv } from "../services/fetch-service";
import { Context } from "./Context.jsx";

const ERROR_MESSAGES = {
  INVALID_ARXIV_LOGIN_PARAMETERS: "Invalid arXiv login parameters",
  UNEXPECTED_ERROR: "Unexpected error",
};

export function a11yClick(keyboardEvent) {
  return !!keyboardEvent && [13, 32].includes(keyboardEvent.charCode || keyboardEvent.keyCode);
}

export const getId = ({ id }) => id;

const SiteLogo = () => (
  <div className="is-flex is-flex-direction-column is-align-items-center">
    <figure className="image is-96x96 mb-0">
      <img className="is-rounded" src={davidLogo} alt="david-logo" />
    </figure>
    <h3 className="title has-text-black mb-0">IArxiv<sup className="beta-label"> beta</sup></h3>
  </div>
);

const SiteLinks = () => (
  <div className="has-text-centered">
    <Link to="/about">About us</Link>
    <span className="has-text-grey">&nbsp;·&nbsp;</span>
    <Link to="/faq">FAQ</Link>
  </div>
);

const LoginWithArxivPlaceholder = () => (
  <div className="has-text-centered">
    <Spin className="mb-4" size="large" />
    <h2 className="title is-4">Logging into IArxiv...</h2>
    <h3 className="title is-5 has-text-grey">We are configuring your profile, please wait a few seconds</h3>
  </div>
);

export const Category = ({ active, className, label, onClick }) => (
  <button
    type="button"
    className={`category button is-outlined is-rounded ${active ? "active" : ""} ${className}`}
    style={{ borderWidth: "2px" }}
    onClick={onClick}
    onKeyPress={(e) => a11yClick(e) && onClick()}
  >
    {active && <span className="mr-2" data-testid="checked-category-icon"><CheckOutlined /></span>}
    <span className="has-text-weight-bold">{label}</span>
  </button>
);

const CategoriesSelect = ({ options, value = [], onChange }) => {
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState(value);

  const onCategorySelected = (category) => {
    if (selectedCategoriesIds.includes(category.id)) {
      const categoryIndex = selectedCategoriesIds.indexOf(category.id);
      selectedCategoriesIds.splice(categoryIndex, 1);
    } else {
      selectedCategoriesIds.push(category.id);
    }
    setSelectedCategoriesIds([...selectedCategoriesIds]);
    onChange([...selectedCategoriesIds]);
  };

  return (
    <Row gutter={[12, 12]}>
      {options.map((category) => (
        <Col key={category.id} xs={24} sm={24} md={6} xl={12} xxl={6}>
          <Category
            className="is-fullwidth"
            label={category.code}
            active={selectedCategoriesIds.includes(category.id)}
            onClick={() => onCategorySelected(category)}
          />
        </Col>
      ))}
    </Row>
  );
};

const SetPasswordAndLoginModal = ({
  isVisible,
  onClose,
  onSuccess,
  user,
 }) => {
  const [loading, setLoading] = useState(false);
  const { getValues, register, handleSubmit, formState: { errors } } = useForm();
  const accessToken = useAccessToken();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await fetchUpdatePassword(accessToken, data.password);
      setLoading(false);
      onSuccess();
    } catch (error) {
      console.error(error);
      message.error(error.message || ERROR_MESSAGES.UNEXPECTED_ERROR);
    }
  };

  return (
    <div className={`modal ${isVisible ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose} />
      <div className="modal-content">
        <div className="box p-6">
          <h2 className="title is-4">Set password</h2>
          <div className="subtitle is-5 has-text-grey has-text-weight-semibold">
            By setting your password now, you'll be able to login into iArxiv directly with <span className="has-text-primary-blue">{user.email}</span>
          </div>

          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <input type="text" className="is-hidden" autoComplete="username" />
            <div className="field">
              <div className="control has-icons-left">
                <input
                  type="password"
                  className={`input is-large ${errors.password ? "is-danger" : ""}`}
                  placeholder="Password"
                  name="password"
                  autoComplete="new-password"
                  ref={register({
                    required: "The password is required",
                    minLength: { value: 6, message: "Password must have at least 6 characters" },
                  })}
                />
                <span className="icon is-small is-left">
                  <LockOutlined />
                </span>
              </div>
              {errors.password && <p className="help is-danger">{errors.password.message}</p>}
            </div>
            <div className="field">
              <div className="control has-icons-left">
                <input
                  type="password"
                  className={`input is-large ${errors.passwordConfirmation ? "is-danger" : ""}`}
                  placeholder="Confirm password"
                  name="passwordConfirmation"
                  autoComplete="new-password"
                  ref={register({
                    required: "The password confirmation is required",
                    validate: {
                      passwordMatch: confirmation => getValues("password") === confirmation || "Passwords don't match",
                    },
                  })}
                />
                <span className="icon is-small is-left">
                  <LockOutlined />
                </span>
              </div>
              {errors.passwordConfirmation && <p className="help is-danger">{errors.passwordConfirmation.message}</p>}
            </div>
            <div className="is-flex is-justify-content-center">
              <button
                type="submit"
                className={`button is-block is-info is-medium mt-4 ${loading ? "is-loading" : ""}`}
              >
                Set password and Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      />
    </div>
  );
};

const LoginWithArxiv = ({ user }) => {
  const [availableCategories, setAvailableCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [passwordModalIsVisible, setPasswordModalIsVisible] = useState(false);
  const history = useHistory();
  const { session, setSession } = useContext(Context);
  const accessToken = useAccessToken();
  const [form] = Form.useForm();

  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      try {
        setLoading(true);
        const { data: categories } = await fetchGetCategories(accessToken);
        if (!didCancel) setAvailableCategories(categories);
      } catch (error) {
        console.error(error);
        message.error(error.message || ERROR_MESSAGES.UNEXPECTED_ERROR);
      } finally {
        setLoading(false);
      }
    }

    if (accessToken) fetchData();

    return () => {
      didCancel = true;
    };
  }, [accessToken]);

  const goToHomepage = () => history.push("/home");

  const getCategoriesSelectMessage = () => {
    let message = "We found the following categories of your interest";

    if (user.unsupportedCategories) {
      message = "We found that you are interested in categories that we don't currently support, but don't worry! we'll notify you when we do";
    }

    if (!user.categories.length) {
      message = "We couldn't find categories of your interest, please select at least one of the currently supported";
    }

    return `Welcome to IArxiv! ${message}`;
  };

  const updateUserSettings = async (userSettings) => {
    let success;

    try {
      setLoading(true);
      const { data } = await fetchUpdateSettingsIntegration(accessToken, userSettings);
      setSession({ ...session, ...data });
      success = true;
    } catch (error) {
      console.error(error);
      message.error(error.message || ERROR_MESSAGES.UNEXPECTED_ERROR);
      success = false;
    } finally {
      setLoading(false);
      return success;
    }
  };

  const login = async (userSettings) => {
    const userSettingsUpdated = await updateUserSettings(userSettings);
    if (userSettingsUpdated) goToHomepage();
  };

  const onPasswordModalOpened = async () => {
    try {
      await form.validateFields();
      setPasswordModalIsVisible(true);
    } catch (error) {
      setPasswordModalIsVisible(false);
    }
  };

  const onPasswordSet = async () => {
    setPasswordModalIsVisible(false);
    const userSettings = await form.getFieldsValue();
    const userSettingsUpdated = await updateUserSettings(userSettings);
    if (userSettingsUpdated) goToHomepage();
  };

  if (loading) return (
    <>
      <Skeleton active title paragraph={{ rows: 2 }} />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space wrap>
          <Skeleton.Button active shape="round" size="large" />
          <Skeleton.Button active shape="round" size="large" />
          <Skeleton.Button active shape="round" size="large" />
          <Skeleton.Button active shape="round" size="large" />
        </Space>
        <div className="mt-4">
          <Skeleton.Button active size="default" shape="default" />
        </div>
        <div className="has-text-centered mt-5">
          <Skeleton.Input style={{ width: 200 }} active size="large" />
        </div>
      </Space>
    </>
  );

  return (
    <>
      <h2 className="title is-4 mb-5">Hello, <span className="has-text-primary-blue">{user?.firstname}!</span></h2>
      <Form
        scrollToFirstError
        layout="vertical"
        requiredMark="optional"
        size="large"
        form={form}
        colon={false}
        onFinish={login}
        initialValues={{
          categories: user.categories.map(getId),
          weeklyEmail: user.weeklyEmail,
          dailyEmail: user.dailyEmail || true,
        }}
      >
        <Form.Item
          name="categories"
          label={(<div className="is-size-5 has-text-weight-medium mb-5">{getCategoriesSelectMessage()}</div>)}
          extra={(<div className="mt-1">You can add or remove categories clicking on them</div>)}
          rules={[{ required: true, message: "Please select at least one category" }]}
        >
          <CategoriesSelect options={availableCategories} />
        </Form.Item>

        <div className="mb-2">
          <label htmlFor="user-settings-daily-emails" className="mr-2">Receive daily email</label>
          <Form.Item noStyle name="dailyEmail" valuePropName="checked" >
            <Switch id="user-settings-daily-emails" />
          </Form.Item>
        </div>

        <div className="mb-5">
          <label htmlFor="user-settings-weekly-emails" className="mr-2">Receive weekly digest email</label>
          <Form.Item noStyle name="weeklyEmail" valuePropName="checked" >
            <Switch id="user-settings-weekly-emails" />
          </Form.Item>
        </div>
        <Row justify="center">
          <Col xs={24} sm={24} md={12} xl={12}>
            <div className="is-flex is-flex-direction-column">
                <button
                  type="submit"
                  className={`button has-text-weight-bolds mb-3 is-block is-medium is-info ${loading ? "is-loading" : ""}`}
                >
                  Login
                </button>
                <div className="is-between-lines has-text-grey">or</div>
                <button
                  type="button"
                  className="button has-text-primary-blue"
                  style={{ border: "none" }}
                  onClick={onPasswordModalOpened}
                >
                  Set your password and login
              </button>
            </div>
          </Col>
        </Row>
      </Form>
      <SetPasswordAndLoginModal
        isVisible={passwordModalIsVisible}
        onClose={() => setPasswordModalIsVisible(false)}
        onSuccess={onPasswordSet}
        user={user}
      />
    </>
  );
};

export default function LoginWithArxivScreen() {
  const [arxivUser, setArxivUser] = useState();
  const [loading, setLoading] = useState(true);
  const { setSession } = useContext(Context);
  const queryString = useQueryString();
  const history = useHistory();

  const arxivAssertion = queryString.get("assertion");
  const arxivDigest = queryString.get("digest");
  useEffect(() => {
    let didCancel = false;

    async function loginWithArxiv() {
      try {
        setLoading(true);
        const { data } = await fetchLoginWithArxiv(arxivAssertion, arxivDigest);
        if (!didCancel) {
          setSession(data);
          setArxivUser(data);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        message.error(ERROR_MESSAGES.INVALID_ARXIV_LOGIN_PARAMETERS);
      }
    }

    if (arxivAssertion && arxivDigest) {
      loginWithArxiv();
    } else {
      // @TODO: Go to arXiv
      message.error(ERROR_MESSAGES.INVALID_ARXIV_LOGIN_PARAMETERS);
    }

    return () => {
      didCancel = true;
    };
  }, [arxivAssertion, arxivDigest, setSession, history]);

  useEffect(() => {
    console.log({ arxivUser});
    if (arxivUser?.accessToken && !arxivUser?.newUser) {
      history.push("/home");
    }
  }, [arxivUser, history]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Header className="p-5 pt-6" style={{ background: "none", height: "100%" }}>
        <SiteLogo />
      </Layout.Header>
      <Layout.Content className="p-5">
        <Row justify="center">
          <Col xs={24} sm={24} xl={12}>
            <div className="box p-6">
              {loading
                ? <LoginWithArxivPlaceholder />
                : <LoginWithArxiv user={arxivUser || {}} />}
            </div>
          </Col>
        </Row>
      </Layout.Content>
      <Layout.Footer>
        <SiteLinks />
      </Layout.Footer>
    </Layout>
  );
}
