/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { defaultCatchValidation, fetchRegister } from "../services/fetch-service";
import { Link } from "react-router-dom";
import davidLogo from "../images/david-logo.jpg";

let registerAbortController = new AbortController();

export default function RegisterScreen ({history}) {
  const { register, handleSubmit, errors, watch } = useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = data => {
    if (loading) {
      return;
    }
    if (data.email && data.password && data.firstname && data.lastname) {
      setLoading(true);
      setErrorMessage("");
      registerAbortController.abort();
      registerAbortController = new AbortController();
      
      fetchRegister(registerAbortController, data.email, data.password, data.firstname, data.lastname)
      .then(json => {
        if (json.success) {
          setSuccess(json.success);
        }
      })
      .catch(json => {
        defaultCatchValidation(json, history);

        if (json.errorCode && json.message) {
          setErrorMessage(json.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <div className="register-screen">
      <section className="hero has-background-white-bis is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            {success ? (
            <div className="column is-4 is-offset-4">
              <h3 className="title has-text-black">Last step</h3>
              <p className="subtitle has-text-black">Check your inbox to confirm your registration.</p>
              <p className="subtitle has-text-black">If you do not receive any incoming email within 15 minutes or less, check your spam folder.</p>
              <p className="subtitle has-text-black">You can <Link to="/login">log in</Link> with the unconfirmed email and we will send you a new confirmation email.</p>
            </div>
            ) : (
            <div className="column is-4 is-offset-4">
              <div className="david-logo-container">
                <img src={davidLogo} alt="david-logo"/>
              </div>
              <p className="has-text-grey">
                <Link to="/about">About us</Link>
                <label>&nbsp;·&nbsp;</label>
                <Link to="/faq">F.A.Q.</Link>
              </p>
              <h3 className="title has-text-black">Register</h3>
              <p className="subtitle has-text-black">Complete form to create account.</p>
              <div className="box">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="email"
                        placeholder="Email"
                        autoFocus=""
                        name="email"
                        ref={register({
                          required: "Required",
                          maxLength: { value: 50, message: "Too long" },
                          pattern: {
                            value: RegExp(
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/
                            ),
                            message: "Invalid"
                          }
                        })}
                      />
                      {errors.email && (
                        <span className="has-text-danger">
                          * {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="text"
                        placeholder="Firstname"
                        autoFocus=""
                        name="firstname"
                        ref={register({
                          required: "Required",
                          maxLength: { value: 50, message: "Too long" }
                        })}
                      />
                      {errors.firstname && (
                        <span className="has-text-danger">
                          * {errors.firstname.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="text"
                        placeholder="Lastname"
                        autoFocus=""
                        name="lastname"
                        ref={register({
                          required: "Required",
                          maxLength: { value: 50, message: "Too long" }
                        })}
                      />
                      {errors.lastname && (
                        <span className="has-text-danger">
                          * {errors.lastname.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="password"
                        placeholder="Password"
                        name="password"
                        ref={register({ required: "Required" })}
                      />
                      {errors.password && (
                        <span className="has-text-danger">
                          * {errors.password.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="password"
                        placeholder="Confirm password"
                        name="confirmPassword"
                        ref={register({ required: "Required", validate: (value) => value === watch('password') || "Password must match" })}
                      />
                      {errors.confirmPassword && (
                        <span className="has-text-danger">
                          * {errors.confirmPassword.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="comment-container">
                    <p className="is-italic is-size-6 has-text-weight-light has-text-gr">We'll use your name to fetch and learn from your previous papers in Arxiv</p>
                  </div>

                  <button
                    className={
                      "button is-block is-info is-large is-fullwidth " +
                      (loading ? "is-loading" : "")
                    }
                    type="submit"
                  >
                    Register
                  </button>
                  {errorMessage && (
                    <div className="field">
                      <div className="control">
                        <span className="has-text-danger">{errorMessage}</span>
                      </div>
                    </div>
                  )}
                </form>
              </div>
              <p className="has-text-grey">
                <Link to="/login">Already have account</Link>
              </p>
            </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
