import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="content has-text-centered">
          <div>Thanks for using IArxiv<sup className="beta-label"> beta</sup> !</div>
          <div>
            {"Feedback and updates: "}
            <a href="mailto:sequi@unsam.edu.ar?subject=IArxiv-feedback">sequi@unsam.edu.ar</a>
            {" & "}
            <a href="https://twitter.com/iarxiv_org" rel="noopener noreferrer" target="_blank">twitter.com/iarxiv_org</a>
          </div>
          <div>
            {"Powered by "}
            <a href="http://icas.unsam.edu.ar/" rel="noopener noreferrer" target="_blank">ICAS</a>
            {", "}
            <a href="https://easytechgreen.com/" rel="noopener noreferrer" target="_blank">Easytech</a>
            {", "}
            <a href="https://physics.illinois.edu/" rel="noopener noreferrer" target="_blank">Illinois Physics</a>
            {", "}
            <a href="https://vercel.com?utm_source=iarxiv&utm_campaign=oss" rel="noopener noreferrer" target="_blank">Vercel</a>
            {" & "}
            <a href="http://unsam.edu.ar/" rel="noopener noreferrer" target="_blank">ECyT</a>
          </div>


        </div>
      </footer>
    </div>
  );
};

export default Footer;
