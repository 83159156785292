import React, { useContext, useEffect } from "react";
import { Context } from "./Context.jsx";

export default function ForbiddenScreen ({ history }) {
  const context = useContext(Context);

  useEffect(() => {
    history.push("/home");
  }, [context, history]);

  return <div>Forbidden page.</div>;
};
