import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { defaultCatchValidation, fetchUnsubscribeDailyEmail } from "../services/fetch-service";

export default function UnsubscribeScreen ({ history }) {
  let { token } = useParams();

  const [message, setMessage] = useState("Request in progress...");

  useEffect(() => {
    if (token) {
      fetchUnsubscribeDailyEmail(token)
      .then(json => {
        if (json.success) {
          setMessage("Unsubscribe success.");
        } else {
          setMessage("Error during unsubscribe request.");
        }
      })
      .catch(json => {
        defaultCatchValidation(json, history);
      });
    }
  }, [token, history]);

  return (
    <div className="register-screen">
      <section className="hero has-background-white-bis is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-4 is-offset-4">
              <h3 className="title has-text-black">Unsubscribe from daily emails</h3>
              <p className="subtitle has-text-black">{message}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
