import React from "react";
import Navbar from "./Navbar.jsx";
import Footer from "./Footer.jsx";
import Accordion from "react-collapsy";
import davidLogo from "../images/david-logo.jpg";

export default function FAQScreen () {

  const faqs = [
    {
      question: (<label>What does IArxiv do ?</label>),
      answer: (<div>
                <p>IArxiv.org fetches Arxiv.org papers every day and sorts them according to each user preferences. Therefore, everyday each user can read the Arxiv papers through IArxiv in a much more easy and pleasant way!</p>
              </div>)
    },
    {
      question: (<label>How does IArxiv learn which are the preferences of each user ?</label>),
      answer: (<div>
                <p>There are two ways to learn which paper the user likes. The first one occurs during registration, where IArxiv fetches previous user's papers (based on the user first and last names). And the second occurs everyday when the user clicks on papers to open them.</p>
                <p>In both cases IArxiv runs an unsupervised Machine Learning algorithm (called LDA) to extract which topics and in which proportions are present in each paper, to construct a vector of topics for each user. This personal vector of topics is the key to sort new Arxiv releases according to each user preferences.</p>
              </div>)
    },
    {
      question: (<label>How often am I supposed to enter to IArxiv ?</label>),
      answer: (<div>
                <p>Usually, scientists enter everyday to check each new Arxiv release. However, if you go in vacations, or you had a busy week, IArxiv also offers to select a slice of dates and sort them altogether for a more easy return to work!</p>
              </div>)
    },
    {
      question: (<label>What categories are included in IArxiv ?</label>),
      answer: (<div>
                <p>By now astro-ph, gr-qc, hep-ph and hep-th.  We plan to include more as time goes by.</p>
              </div>)
    },
    {
      question: (<label>Can I read many categories simultaneously ?</label>),
      answer: (<div>
                <p>Yes, you can choose that in settings. Every time you click a paper, the algorithm learns whether is from one or other category or cross-listing, and learns within the topics of each category.</p>
                </div>)
    },
    {
      question: (<label>Is there a mobile version ?</label>),
      answer: (<div>
                <p>Yes, and currently working!"</p>
              </div>)
    },
    {
      question: (<label>Where can I learn more details about IArxiv ?</label>),
      answer: (<div>
                <p><a href="https://arxiv.org/abs/2002.02460" target="_blank" rel="nofollow noopener noreferrer">https://arxiv.org/abs/2002.02460</a></p>
              </div>)
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="faq-screen">
        <section className="hero is-info">
          <div className="hero-body">
            <div className="container box full-height">
              <div className="columns is-multiline is-centered">
                <div className="column is-narrow">
                  <div className="content">
                    <h5>Frequently Asked Questions</h5>
                  </div>
                </div>
              </div>

              <div className="columns is-multiline align-bottom is-centered">
                <div className="column is-narrow">
                  <div className="content has-text-centered">
                    <div className="david-logo-container">
                      <img src={davidLogo} alt="david-logo"/>
                    </div>
                    <h3 className="title has-text-black">IArxiv<sup className="beta-label"> beta</sup></h3>
                    <hr/>
                  </div>
                </div>
              </div>

              <section className="container">
                <div className="content">
                {faqs.map((item, index) => (
                  <Accordion
                    key={index}
                    className="accordion"
                    headerClass="accordion-header"
                    title={item.question}
                  >
                    <div className="paper-abstract">
                    {item.answer}
                    </div>
                  </Accordion>
                ))}
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
