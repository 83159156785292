/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from "react";
import { Context } from "./Context";
import { Link } from "react-router-dom";
import davidLogo from "../images/david-logo.jpg";

const Navbar = () => {
  const context = useContext(Context);
  const [active, setActive] = useState("");
  const toggleMenu = () => {
    setActive(!active);
  };
  return (
    <div className="navbar-screen">
      <nav className="navbar has-shadow">
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <div className="david-logo-container">
                <img src={davidLogo} alt="david-logo"/>
              </div>
              <h1 className="title">IArxiv<sup className="beta-label"> beta</sup></h1>
            </Link>
            {context.session.accessToken && (
            <div
              className={"navbar-burger burger " + (active ? "is-active" : "")}
              data-target="navMenu"
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            )}
          </div>
          {context.session.accessToken ? (
          <div className={"navbar-menu " + (active ? "is-active" : "")}>
            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">{`${context.session.lastname}, ${context.session.firstname} (${context.session.email})`}</a>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/home">
                    Home
                  </Link>
                  <Link className="navbar-item" to="/settings">
                    Settings
                  </Link>
                  <hr className="navbar-divider" />
                  <Link className="navbar-item" to="/about">
                    About us
                  </Link>
                  <Link className="navbar-item" to="/faq">
                    F.A.Q.
                  </Link>
                  <hr className="navbar-divider" />
                  <Link className="navbar-item" to="/logout">
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
          ) : (
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <Link className="button" to="/login">
                    Login
                  </Link>
                </p>
                <p className="control">
                  <Link className="button is-info" to="/register">
                    Register
                  </Link>
                </p>
              </div>
            </div>
          </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
