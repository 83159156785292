import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import MathJax from "react-mathjax2";
import Select from "react-select";
import { Map } from "immutable";
import { Context } from "./Context.jsx";
import { defaultCatchValidation, fetchGetSettings, fetchUpdateSettings } from "../services/fetch-service";
import Navbar from "./Navbar.jsx";
import Footer from "./Footer.jsx";
import { Spin, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

let updateCategoriesAbortController = new AbortController();

export default function SettingsScreen ({ history }) {
  const context = useContext(Context);
  if (context.session.accessToken === undefined) {
    history.push("/login");
  }

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [allCategories, setAllCategories] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [selectedCategoriesOptions, setSelectedCategoriesOptions] = useState(
    []
  );
  const [currentUserCategories, setCurrentUserCategories] = useState([]);

  const [currentAbstractThresholdScore, setCurrentAbstractThresholdScore] = useState(0);
  const [abstractThresholdScore, setAbstractThresholdScore] = useState(0);

  const [currentCheckedDailyEmail, setCurrentCheckedDailyEmail] = useState(false);
  const [checkedDailyEmail, setCheckedDailyEmail] = useState(false);

  const [currentCheckedWeeklyEmail, setCurrentCheckedWeeklyEmail] = useState(false);
  const [checkedWeeklyEmail, setCheckedWeeklyEmail] = useState(false);

  const [currentDigestThresholdScore, setCurrentDigestThresholdScore] = useState(0);
  const [digestThresholdScore, setDigestThresholdScore] = useState(0);

  const [papers, setPapers] = useState([]);
  const [checkedPapers, setCheckedPapers] = useState(Map({}));
  const [checkedPapersChanged, setCheckedPapersChanged] = useState(false);

  useEffect(() => {
    if (context.session.accessToken === undefined) {
      return;
    }

    const setOptions = (systemCategories, userCategories) => {
      setAllCategories(systemCategories);
      let options = [];
      systemCategories.forEach(category => {
        options.push({
          value: category.id,
          label: category.code
        });
      });
      setCategoriesOptions(options);

      let selectedOptions = [];

      userCategories.forEach(category => {
        selectedOptions.push({
          value: category.id,
          label: category.code
        });
      });

      setSelectedCategoriesOptions(selectedOptions);
      setCurrentUserCategories(selectedOptions);
    };

    const setPapersAsYes = papers => {
      if (Array.isArray(papers) && papers.length > 0) {
        let map = Map({});
        papers.forEach(paper => {
          map = map.set(paper.id, {isOwner: true, age: parseInt(moment.utc().format("YYYY")) - parseInt(moment(paper.publishDate).format("YYYY"))});
        });

        setPapers(papers);
        setCheckedPapers(map);
        setCheckedPapersChanged(true);
      }
    };

    setOptions([], []);
    fetchGetSettings(context.session.accessToken)
    .then(json => {
      if (json.abstractThresholdScore) {
        setAbstractThresholdScore(json.abstractThresholdScore);
        setCurrentAbstractThresholdScore(json.abstractThresholdScore)
      }

      if (json.dailyEmail) {
        setCheckedDailyEmail(json.dailyEmail)
        setCurrentCheckedDailyEmail(json.dailyEmail)
      }

      if (json.weeklyEmail) {
        setCheckedWeeklyEmail(json.weeklyEmail)
        setCurrentCheckedWeeklyEmail(json.weeklyEmail)
      }

      if (json.digestThresholdScore) {
        setDigestThresholdScore(json.digestThresholdScore);
        setCurrentDigestThresholdScore(json.digestThresholdScore)
      }

      if (json.systemCategories && json.userCategories && json.papers ) {
        setOptions(json.systemCategories, json.userCategories);
        setPapersAsYes(json.papers);
      }
    })
    .catch(json => {
      defaultCatchValidation(json, history);

      if (json.errorCode && json.message) {
        setErrorMessage(json.message);
      }
    })
    .finally(() => {
      setLoading(false);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.session.accessToken]);

  const abstractThresholdScoreChanged = () => {
    return currentAbstractThresholdScore !== abstractThresholdScore
  }

  const checkedDailyEmailChanged = () => {
    return currentCheckedDailyEmail !== checkedDailyEmail
  }

  const checkedWeeklyEmailChanged = () => {
    return currentCheckedWeeklyEmail !== checkedWeeklyEmail
  }

  const digestThresholdScoreChanged = () => {
    return currentDigestThresholdScore !== digestThresholdScore
  }

  const categoriesChanged = () => {
    if (
      !Array.isArray(selectedCategoriesOptions) ||
      !Array.isArray(currentUserCategories) ||
      selectedCategoriesOptions.length !== currentUserCategories.length
    ) {
      return true;
    }
    selectedCategoriesOptions.sort((a, b) =>
      a.value > b.value ? 1 : b.value > a.value ? -1 : 0
    );
    currentUserCategories.sort((a, b) =>
      a.value > b.value ? 1 : b.value > a.value ? -1 : 0
    );

    for (var i = 0; i < selectedCategoriesOptions.length; i++) {
      if (
        selectedCategoriesOptions[i].value !== currentUserCategories[i].value
      ) {
        return true;
      }
    }
    return false;
  };

  const someSettingHasChanged = () => {
    return (
      categoriesChanged() ||
      abstractThresholdScoreChanged() ||
      checkedDailyEmailChanged() ||
      checkedWeeklyEmailChanged() ||
      digestThresholdScoreChanged() ||
      checkedPapersChanged
    );
  }

  const updateCheckedPapers = (paperID, isOwner, paperAge) => {
    const map = checkedPapers.set(paperID, {isOwner: isOwner, age: parseInt(moment.utc().format("YYYY")) - parseInt(paperAge)});
    setCheckedPapers(map);
    if (!checkedPapersChanged) {
      setCheckedPapersChanged(true);
    }
  };

  const onUpdateSettings = () => {
    if (loading) {
      return;
    }
    if (someSettingHasChanged()) {
      if (
        !RegExp(/^\d{1}(?:\.\d{0,2})?$/).test(abstractThresholdScore) ||
        abstractThresholdScore > 1 ||
        abstractThresholdScore < 0
      ) {
        setErrorMessage("Abstract threshold score must be a number between 0 and 1 with 2 decimals.");
        return;
      }

      if (
        !RegExp(/^\d{1}(?:\.\d{0,2})?$/).test(digestThresholdScore) ||
        digestThresholdScore > 1 ||
        digestThresholdScore < 0
      ) {
        setErrorMessage("Digest threshold score must be a number between 0 and 1 with 2 decimals.");
        return;
      }

      if (
        !Array.isArray(selectedCategoriesOptions) ||
        selectedCategoriesOptions.length === 0
      ) {
        setErrorMessage("Choose at least one category.");
        return;
      }

      let followedCategoriesID = [];
      selectedCategoriesOptions.forEach(option => {
        followedCategoriesID.push(option.value);
      });

      let ownPapers = [];
      let notOwnPapers = [];
      checkedPapers.forEach(({isOwner, age}, paperID) => {
        isOwner ? ownPapers.push({paperID: paperID, age: age}) : notOwnPapers.push(paperID);
      });

      setLoading(true);
      setErrorMessage("");
      updateCategoriesAbortController.abort();
      updateCategoriesAbortController = new AbortController();
      
      fetchUpdateSettings(
        updateCategoriesAbortController,
        context.session.accessToken,
        categoriesChanged() ? followedCategoriesID : null,
        checkedPapersChanged ? { ownPapers: ownPapers, notOwnPapers: notOwnPapers } : null,
        abstractThresholdScore,
        checkedDailyEmail,
        checkedWeeklyEmail,
        digestThresholdScore,
      )
      .then(json => {
        if (json.success) {
          // Set user data in context.
          const newCategories = [];
          followedCategoriesID.forEach(categoryID => {
            newCategories.push(
              allCategories.find(category => category.id === categoryID)
            );
          });
          context.setSession({
            firstname: context.session.firstname,
            lastname: context.session.lastname,
            email: context.session.email,
            accessToken: context.session.accessToken,
            expireTime: context.session.expireTime,
            categories: newCategories
          });
          history.push("/home");
        }
      })
      .catch(json => {
        defaultCatchValidation(json, history);

        if (json.errorCode && json.message) {
          setErrorMessage(json.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <div>
      <Navbar />
      <div className="settings-screen">
        <section className="hero is-info">
          <div className="hero-body">
            <div className="container box full-height">
              <div className="columns is-multiline is-centered">
                <div className="column is-narrow">
                  <div className="content">
                    <h5>Settings</h5>
                  </div>
                </div>
              </div>

              <div className="columns is-multiline align-bottom">
                <div className="column is-narrow">
                  <div className="content">
                    <span>Followed categories </span>
                    <Tooltip placement="top" title="If you follow more than one category, all papers are shown together and sorted according to their score in each category">
                          <InfoCircleOutlined />
                        </Tooltip>
                    <Spin spinning={loading}>
                      <Select
                        isMulti
                        options={categoriesOptions}
                        value={selectedCategoriesOptions}
                        onChange={setSelectedCategoriesOptions}
                      />
                    </Spin>
                  </div>
                </div>
              </div>
              <div className="columns is-multiline align-bottom">
                <div className="column is-narrow">
                  <div className="content">
                    <span>Abstract Threshold Score </span>
                    <Tooltip placement="top" title="Papers below this score only title is shown">
                      <InfoCircleOutlined />
                    </Tooltip>
                    <Spin spinning={loading}>
                      <input
                        className="input"
                        type="number"
                        min="0"
                        max="1"
                        step="0.01"
                        placeholder=""
                        value={abstractThresholdScore}
                        onChange={e => setAbstractThresholdScore(parseFloat(e.target.value))}
                      />
                    </Spin>
                  </div>
                </div>
              </div>
              <div className="columns is-multiline align-bottom">
                <div className="column is-narrow">
                  <div className="content">
                    <span>Receive daily email </span>
                    <Tooltip placement="top" title="Receive a daily email with your papers sorted according to your preferences">
                      <InfoCircleOutlined />
                    </Tooltip>
                    <Spin spinning={loading}>
                      <div className="radio-group">
                        <input
                          type="radio"
                          id={`daily-email-yes`}
                          name={`daily-email`}
                          checked={checkedDailyEmail}
                          onChange={() => setCheckedDailyEmail(true)}
                        />
                        <label htmlFor={`daily-email-yes`}>
                          Yes
                        </label>
                        <input
                          type="radio"
                          id={`daily-email-no`}
                          name={`daily-email`}
                          checked={!checkedDailyEmail}
                          onChange={() => setCheckedDailyEmail(false)}
                        />
                        <label htmlFor={`daily-email-no`}>
                          No
                        </label>
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
              <div className="columns is-multiline align-bottom">
                <div className="column is-narrow">
                  <div className="content">
                    <span>Receive weekly email </span>
                    <Tooltip placement="top" title="Receive on Saturday morning an email with all papers in the last 7 days that have scores above your digest threshold">
                      <InfoCircleOutlined />
                    </Tooltip>
                    <Spin spinning={loading}>
                      <div className="radio-group">
                        <input
                          type="radio"
                          id={`weekly-email-yes`}
                          name={`weekly-email`}
                          checked={checkedWeeklyEmail}
                          onChange={() => setCheckedWeeklyEmail(true)}
                        />
                        <label htmlFor={`weekly-email-yes`}>
                          Yes
                        </label>
                        <input
                          type="radio"
                          id={`weekly-email-no`}
                          name={`weekly-email`}
                          checked={!checkedWeeklyEmail}
                          onChange={() => setCheckedWeeklyEmail(false)}
                        />
                        <label htmlFor={`weekly-email-no`}>
                          No
                        </label>
                      </div>
                    </Spin>
                  </div>
                </div>
                <div className="column is-narrow">
                  <div className="content">
                    <span>Digest Threshold Score </span>
                    <Tooltip placement="top" title="Papers below this score will be ignored in weekly email">
                      <InfoCircleOutlined />
                    </Tooltip>
                    <Spin spinning={loading}>
                      <input
                        className="input"
                        type="number"
                        min="0"
                        max="1"
                        step="0.01"
                        placeholder=""
                        value={digestThresholdScore}
                        onChange={e => setDigestThresholdScore(parseFloat(e.target.value))}
                      />
                    </Spin>
                  </div>
                </div>
              </div>
              {Array.isArray(papers) && papers.length > 0 && (
                <div>
                  <div className="columns is-multiline">
                    <div className="column is-narrow">
                      <div className="content">
                        <span>Check if the next paper(s) is yours </span>
                        <Tooltip placement="top" title="By selecting your papers IArxiv learns faster to sort papers according to your preferences">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <MathJax.Context
                    input="tex"
                    onLoad={() => console.log("Loaded MathJax script!")}
                    onError={(MathJax, error) => {
                      console.warn(error);
                      console.log(
                        "Encountered a MathJax error, re-attempting a typeset!"
                      );
                      MathJax.Hub.Queue(MathJax.Hub.Typeset());
                    }}
                    script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-AMS-MML_HTMLorMML"
                    options={{
                      asciimath2jax: {
                        useMathMLspacing: true,
                        delimiters: [["\\(", "\\)"]],
                        preview: "none"
                      }
                    }}
                  >
                    <div>
                      {papers.map(paper => (
                        <div
                          key={paper.id}
                          className="columns is-vcentered paper-row"
                        >
                          <div className="column is-narrow">
                            <div className="content">
                              <div className="radio-group">
                                <input
                                  type="radio"
                                  id={`check-${paper.id}-yes`}
                                  name={`check-${paper.id}`}
                                  checked={checkedPapers.get(paper.id) && checkedPapers.get(paper.id).isOwner}
                                  onChange={updateCheckedPapers.bind(
                                    this,
                                    paper.id,
                                    true,
                                    moment(paper.publishDate).format("YYYY")
                                  )}
                                />
                                <label htmlFor={`check-${paper.id}-yes`}>
                                  Yes
                                </label>
                                <input
                                  type="radio"
                                  id={`check-${paper.id}-no`}
                                  name={`check-${paper.id}`}
                                  checked={checkedPapers.get(paper.id) && !checkedPapers.get(paper.id).isOwner}
                                  onChange={updateCheckedPapers.bind(
                                    this,
                                    paper.id,
                                    false,
                                    moment(paper.publishDate).format("YYYY")
                                  )}
                                />
                                <label htmlFor={`check-${paper.id}-no`}>
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="column">
                            <div className="content" paperid={paper.id}>
                              <h4>
                                <MathJax.Text text={paper.title} />
                              </h4>
                              <p>
                                <i>
                                  {`(${moment(paper.publishDate).format(
                                    "YYYY/MM/DD"
                                  )}) `}
                                </i>
                              </p>
                              <p>
                                <i>Similar author name: </i>
                                {paper.authorNames.join(" - ")}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </MathJax.Context>
                </div>
              )}
              <div className="columns is-multiline save-button-container align-bottom">
                {someSettingHasChanged() && (
                  <div className="column is-narrow">
                    <div className="content">
                      <button
                        className={
                          "button is-block is-info is-fullwidth " +
                          (loading ? "is-loading" : "")
                        }
                        onClick={onUpdateSettings}
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                )}
                {errorMessage && (
                  <div className="column is-narrow">
                    <div className="content">
                      <span className="span-error has-text-danger">
                        {errorMessage}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
