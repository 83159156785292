import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { defaultCatchValidation, fetchUpdateAffinityToken } from "../services/fetch-service";

export default function PaperScreen ({ history }) {
  let { token, hexURL } = useParams();

  const [message, setMessage] = useState("Redirecting...");

  useEffect(() => {
    if (token) {
      fetchUpdateAffinityToken(token)
      .then(json => {
        if (json.success) {
          if (hexURL) {
            var url = ''
            for (var i = 0; i < hexURL.length; i+=2) {
              url += String.fromCharCode(parseInt(hexURL.substr(i, 2), 16))
            }
            window.location.replace(url);
          } else {
            setMessage("Bad URL");
          }
        } else {
          setMessage("Bad URL");
        }
      })
      .catch(json => {
        defaultCatchValidation(json, history);
        setMessage("Bad URL");
      });
    }
  }, [token, hexURL, history]);

  return <div>{message}</div>;
};
