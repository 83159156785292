import { apiConfig } from '../environment/config';

/**
* @param endpoint String field set in environment.endpoints
* @returns String with the complete URL for an endpoint set in environment file.
*/
function getURL (endpoint) {
    return `${apiConfig.baseUrl}${apiConfig.endpoints[endpoint]}`;
}

/**
* @param url Sting wich contains an URL with parameters format as :param. For example: /login/:username/:passwd
* @param params Array with objects with the param id and the param value. For example: [{id:'username', value: 'user01'}, {id:'passwd', value: '123'}]
* @returns String with the params of the endpoint replaced. For example: /login/user01/123
*/
function setParams (url, params) {
    params.forEach(param => {
        const regExp = new RegExp(`:${param.id}`);
        if (regExp.test(url)) {
            url = url.replace(`:${param.id}`, replaceSpecialChars(String(param.value)));
        }
    });
    return url;
}

function replaceSpecialChars(str) {
    /*
     * Add more if it's required, source info:
     * https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html
     */
    str = str.replace(/ /g, '%20');
    str = str.replace(/:/g, '%3A');
    str = str.replace(/\n/g, '%0D%0A');
    return str;
}

export { getURL, setParams }
